<template>
  <div>
    <va-card class="mb-4">
      <va-card-title class="display-3 ml-3">
        {{ $t("menu.wallet") }} ID: {{ walletId }}
      </va-card-title>
    </va-card>

    <va-card>
      <va-card-content>
        <div class="row ml-1 mt-2">
          <div class="flex xs6 lg6 xl3">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("wallet_view.balance")
              }}</va-card-title>
              <va-card-content>
                <div class="row flex">
                  <VaChip size="large" color="info">{{
                    wallet.balance
                  }}</VaChip>
                </div>
              </va-card-content>
            </va-card>
          </div>
          <div class="flex xs6 lg6 xl3">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("wallet_view.debet")
              }}</va-card-title>
              <va-card-content>
                <div class="row flex">
                  <VaChip
                    v-if="wallet.debetAllowed"
                    color="success"
                    size="large"
                    >{{ $t("wallet_view.allowed") }}</VaChip
                  >
                  <VaChip v-else color="danger" size="large">
                    {{ $t("wallet_view.disabled") }}
                  </VaChip>
                </div>
              </va-card-content>
            </va-card>
          </div>
          <div class="flex xs12 lg12 xl6" v-if="wallet.debetAllowed">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("wallet_view.debet_limit")
              }}</va-card-title>
              <va-card-content>
                <div class="row flex">
                  <div v-if="wallet.debetLimit != 0 || showDebetLimit">
                    <VaInput
                      v-model="wallet.debetLimitNew"
                      :max="0"
                      :step="debetLimitStep"
                      type="number"
                    />
                    <div
                      v-if="wallet.debetLimitNew != wallet.debetLimit"
                      class="text--center"
                    >
                      <va-button
                        color="danger"
                        size="small"
                        @click="
                          wallet.debetLimitNew = wallet.debetLimit;
                          showDebetLimit = false;
                        "
                        :disabled="disableSetDebet"
                        class="mr-3"
                        >{{ $t("common_forms.cancel") }}</va-button
                      >
                      <va-button
                        color="info"
                        icon="fa-icon fa fa-pencil"
                        size="small"
                        @click="changeDebetLimit"
                        :disabled="disableSetDebet"
                      >
                        {{ $t("common_forms.edit") }}
                      </va-button>
                    </div>
                  </div>
                  <div v-else>
                    <va-button color="success" @click="showDebetLimit = true"
                      >{{ $t("wallet_view.setLimit") }}
                    </va-button>
                  </div>
                </div>
              </va-card-content>
            </va-card>
          </div>
          <div class="flex xs12 lg12 xl12">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("wallet_view.pricing")
              }}</va-card-title>
              <va-card-content>
                <div class="va-table-responsive">
                  <table class="va-table">
                    <tbody>
                      <tr v-for="pp in priceList">
                        <VaChip color="info">
                          <td>
                            <VaChip color="info"
                              >{{ pp.from }} - {{ pp.to }}</VaChip
                            >
                          </td>
                          <td v-for="p in pp.prices">
                            <VaChip color="success">
                              {{ p.amount }} {{ p.currency }}
                            </VaChip>
                          </td>
                        </VaChip>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </va-card-content>
            </va-card>
          </div>
          <div class="flex xs12 lg12 xl12">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("wallet_view.history")
              }}</va-card-title>
              <va-card-content>
                <va-data-table
                  :items="history"
                  :columns="fields"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :no-data-html="$t('tables_common.no_data')"
                  :hoverable="true"
                  :loading="loadingHistory"
                  :striped="true"
                >
                  <template #header(txId)></template>
                  <template #cell(txId)="{ source: txId }">
                    <va-popover
                      :message="`${$t('wallet_view.tx_detail')}`"
                      placement="top"
                    >
                      <va-button
                        flat
                        color="info"
                        icon="fa fa-info-circle"
                        @click="showDetailF(txId)"
                      />
                    </va-popover> </template
                  >>
                </va-data-table>

                <div class="flex-center mt-3">
                  <va-pagination
                    v-if="pages > 1"
                    v-model="currentPage"
                    :pages="pages"
                  />
                </div>
              </va-card-content>
            </va-card>
          </div>
        </div>
      </va-card-content>
    </va-card>
    <va-modal v-model="showDetail" size="large" :hideDefaultActions="true">
      <template #header>
        <p class="flex display-3 mb-5 mt-3 text--center">
          {{ $t("wallet_view.tx_detail") }}
        </p>
      </template>
      <slot>
        <div v-if="loadingDetail" class="row flex justify-center">
          <VaProgressCircle indeterminate color="info" />
        </div>
        <div v-else>
          <va-input
            v-model="txDetail.txId"
            :label="$t('wallet_view.tx_id')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.type"
            :label="$t('wallet_view.tx_type')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.state"
            :label="$t('wallet_view.tx_state')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.balancePrev"
            :label="$t('wallet_view.tx_prev')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.balanceNew"
            :label="$t('wallet_view.tx_new')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.amountTx"
            :label="$t('wallet_view.tx_amount')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.timestamp"
            :label="$t('wallet_view.tx_timestamp')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.subject"
            :label="$t('wallet_view.tx_subject')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.initiator.chaincode"
            :label="$t('wallet_view.tx_cc')"
            color="info"
            readonly
          />
          <va-input
            v-model="txDetail.initiator.gtwService"
            :label="$t('wallet_view.tx_gtw')"
            color="info"
            readonly
          />
        </div>
      </slot>
      <template #footer>
        <va-button color="info" @click="showDetail = false">
          {{ $t("common_forms.close") }}</va-button
        >
      </template>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      walletId: "",
      wallet: {
        balance: 0,
        debetAllowed: false,
        debetLimit: 0,
        debetLimitNew: 0,
      },
      priceList: [],
      showDebetLimit: false,
      perPage: 10,
      perPageOptions: [10, 20, 50, 100],
      currentPage: 1,
      loadingHistory: false,
      history: [],
      txDetail: {},
      loadingDetail: false,
      showDetail: false,
      disableSetDebet: false,
    };
  },

  created() {
    this.getWallet();
    this.getPriceList();
    this.getHistory();
  },

  computed: {
    debetLimitStep() {
      if (this.wallet.debetLimitNew > -100) {
        return 10;
      } else if (this.wallet.debetLimitNew > -250) {
        return 20;
      } else if (this.wallet.debetLimitNew > -500) {
        return 50;
      } else if (this.wallet.debetLimitNew > -1000) {
        return 100;
      } else {
        return 500;
      }
    },
    fields() {
      return [
        {
          key: "type",
          headerTitle: this.$t("wallet_view.tx_type"),
          label: this.$t("wallet_view.tx_type"),
          sortField: "type",
          sortable: true,
        },
        {
          key: "state",
          headerTitle: this.$t("wallet_view.tx_state"),
          label: this.$t("wallet_view.tx_state"),
        },
        {
          key: "subject",
          headerTitle: this.$t("wallet_view.tx_subject"),
          label: this.$t("wallet_view.tx_subject"),
        },
        {
          key: "timestamp",
          headerTitle: this.$t("wallet_view.tx_timestamp"),
          label: this.$t("wallet_view.tx_timestamp"),
          sortField: "timestamp",
          sortable: true,
        },
        {
          key: "amountTx",
          headerTitle: this.$t("wallet_view.tx_amount"),
          label: this.$t("wallet_view.tx_amount"),
        },
        {
          key: "txId",
          headerTitle: "",
          label: "",
          align: "right",
        },
      ];
    },
    pages() {
      const pages =
        this.perPage && this.perPage !== 0
          ? Math.ceil(this.history.length / this.perPage)
          : this.history.length;
      if (pages < this.currentPage) {
        this.currentPage = pages === 0 ? 1 : pages;
      }
      return pages;
    },
  },

  methods: {
    async showDetailF(id) {
      // console.log("Showing detail: " + id);
      this.loadingDetail = true;
      this.showDetail = true;
      let res = await this.loadDetail(id);
      if (!res) {
        this.showDetail = false;
      }
      this.loadingDetail = false;
    },
    async loadDetail(id) {
      let data = await this.backend.getTx(id);
      if (data !== false) {
        this.txDetail = data;
        return true;
      } else {
        this.launchToast(this.$t("tables_common.error_data"), "danger");
        return false;
      }
    },
    changeDebetLimit() {
      // console.log("Changing debet limit to: " + this.wallet.debetLimitNew);
      this.disableSetDebet = true;
      this.backend.setDebetLimit(this.wallet.debetLimitNew).then((data) => {
        if (data !== false) {
          this.wallet = data;
          this.wallet.debetLimitNew = this.wallet.debetLimit;
          this.launchToast(this.$t("wallet_view.debet_set"), "info");
          this.disableSetDebet = false;
        } else {
          this.launchToast(this.$t("wallet_view.error_debet"), "danger");
          this.disableSetDebet = false;
        }
      });
    },
    getWallet() {
      this.backend.getWalletId().then((data) => {
        if (data !== false) {
          this.walletId = data;
        } else {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
      this.backend.getWallet().then((data) => {
        if (data !== false) {
          this.wallet = data;
          this.wallet.debetLimitNew = this.wallet.debetLimit;
        } else {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },
    getPriceList() {
      this.backend.getPriceList().then((data) => {
        if (data !== false) {
          this.priceList = data;
        } else {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },
    async getHistory() {
      this.loadingHistory = true;
      let bookmark = "";
      let res;
      let success = true;
      this.history = [];
      do {
        res = await this.backend.getHistory(25, bookmark);
        if (res === false) {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
          success = false;
          break;
        }
        bookmark = res.bookmark;
        this.history = this.history.concat(res.transactions);
      } while (bookmark !== "" && bookmark !== null);
      if (success) {
        this.loadingHistory = false;
        this.launchToast(this.$t("tables_common.load_done"), "info");
      }
    },
    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },
  },
};
</script>
<style lang="scss">
.formRow {
  min-width: 20vw;
}
</style>
